<template>
  <div class="PracticeView">
    <div class="header">
      <div
        class="exitButton --global-clickable"
        @click="$router.push('home')">
        {{ $t('exit') }}
      </div>
    </div>

    <div class="body">
      <component
        :is="config.PracticeConfiguration"
        v-if="config.PracticeConfiguration"
        ref="xxx"
        @ready-status-changed="setReadyStatus" />
      <div v-else>
        PracticePanel
      </div>
    </div>
    <div class="bottom">
      <div
        class="button --global-clickable"
        :class="{ disabled: !isReady }"
        @click="startPractice()">
        {{ $t('startPractice') }}
      </div>
    </div>
  </div>
</template>

<translations>
  startPractice: 'Start Practice'
  startPractice_no: 'Start øving'
  exit: 'Exit'
  exit_no: 'Tilbake'
</translations>

<script>
export default {
    inject: ['config'],
    data() {
        return {
            isReady: false
        };
    },
    methods: {
        startPractice() {
            this.$refs.xxx.startPractice();
        },
        setReadyStatus(isReady) {
            this.isReady = isReady;
        }
    }
};
</script>

<style scoped lang="scss">
.PracticeView {
    display: flex;
    flex-direction: column;
}

// .PracticePanel {
//   display: flex;
//   flex-direction: column;
//   padding-top: 7em;
// }
.body {
    flex: 1;
    overflow-y: auto;
}

.bottom {
    padding: 2em 2em;
}
.button {
    padding: 1em;
    text-align: center;
    background-color: white;
    color: black;

    &.disabled {
        opacity: 0.2;
        pointer-events: none;
        cursor: default;
    }
}

.header {
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // pointer-events: none;

    display: flex;
}
.exitButton {
    padding: 1em;
    color: rgba(white, 0.5);
    pointer-events: all;
}
</style>
